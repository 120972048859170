import * as React from "react"

// Custom Components
import Layout from "../components/layout"
import Seo from "../components/seo"

// Images
import Lea from "../assets/images/lea-coffee.png"
import PdfIcon from "../assets/images/pdf-icon.png"

// Hooks
import { useBreakpoint } from "../utils/hooks"

const About = () => {
  const { width } = useBreakpoint()
  return (
    <Layout>
      <Seo title="About" />
      <div className="container mx-4 sm:mx-auto max-w-7xl mt-12">
        <h1 className="mb-10 text-left ml-4">Über mich</h1>
        <div className="grid sm:grid-cols-2 gap-32 ml-4  mr-10 sm:mr-0">
          {width <= 640 && <img src={Lea} width={350} />}
          <div className="flex items-center text-xl">
            <div>
              Wie schön, dass du den Weg auf meine Webseite gefunden hast und
              ich mich dir vorstellen darf! Mein Name ist Lea Gremm, ich bin
              eine deutsche Literaturwissenschaftlerin und Lektorin, die im
              Jänner 2022 in das wunderschöne Wien umgesiedelt ist. Neben dem
              Lektorat bin ich hauptberuflich in der Öffentlichkeitsarbeit und
              im Social Media Marketing tätig. Außerdem habe ich eine
              Leidenschaft fürs Schreiben und für Graphic Design.
              <br />
              <br />
              Nachdem ich deutsche und englische Sprach- und
              Literaturwissenschaft sowie Kultur- und Medienwissenschaft an den
              Universitäten Mannheim und Freiburg studiert habe, habe ich
              entschlossen, mich im Bereich Lektorat/Korrektorat selbstständig
              zu machen - ein Feld, in dem ich nun schon seit fast zehn Jahren
              tätig bin. In dieser Zeit habe ich nicht nur als freiberufliche
              Lektorin meines eigenen Kleinunternehmens gearbeitet, sondern auch
              als wissenschaftliche Hilfskraft am Lehrstuhl für Neuere deutsche
              Literatur der Universität Freiburg sowie als freie Mitarbeiterin
              in unterschiedlichen Redaktionen. Wenn du mehr über meinen
              beruflichen und akademischen Werdegang erfahren willst, kannst du
              gerne einen Blick in meinen Lebenslauf werfen, den ich unten auf
              der Seite verlinkt habe.
              <b>
                {" "}
                Mir macht es enorm viel Spaß, mein Sprachgefühl, mein Fachwissen
                und meine Kreativität in jeden Lektoratsprozess einbringen zu
                können und anderen dabei zu helfen, das Beste aus ihren Texten
                herauszuholen!
              </b>
              <br />
              <br />
              In meiner Freizeit schreibe ich gerne eigene Gedichte oder
              Kurzgeschichten mit dem „Sunday Writers’ Club“ oder entdecke die
              schöne Natur rund um Wien bei einer Wanderung. Hin und wieder bin
              ich auch auf einer Poetry Slam-Bühne zu finden. Ich freue mich
              darauf, dich kennenzulernen, und bin gespannt auf deinen Text!
            </div>
          </div>
          <div className="sm:mt-12">
            <div>
              {width > 640 && <img src={Lea} width={500} height={625} />}
            </div>
          </div>
        </div>
        <br />
        <a
          href="/CV 2024 Webseite.pdf"
          className="flex items-center text-blue-700 ml-4 -mt-24 sm:mt-0"
        >
          <img src={PdfIcon} className="h-[40px] mr-2" />
          Lebenslauf und Publikationsliste
        </a>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Layout>
  )
}

export default About
